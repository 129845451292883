.dashboardWrapper{
	display: grid;
    grid-gap: 10px;
    grid-template-columns: 25% auto;
	grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
    "header  header"
	"requests requests"
    "singerList songList "
    "footer  footer";
    color: #444;
	width: 100vw;
  	height: 100vh;
	background-color: black;
	overflow: auto;
}

.header {
	grid-area: header;
}
.requests {
	grid-area: requests;
	height:0px;
	margin-right: 20px;
	margin-top: -30px;
	position: relative;
	z-index: 100;
	text-align: right;
}

.singerList {
	grid-area: singerList;
}
.sideHeader {
	grid-area: sideHeader;
	background-color:black;
	padding:10px;
}
.footer {
	grid-area: footer;
}

.songList {
	grid-area: songList;
}

.songList, .singerList {
	position: relative;
	border-radius: 0.375rem;
	background-color: rgb(18,18,18);
	display: grid;
	grid-template-rows: auto 1fr;
}

.singerList::-webkit-scrollbar,
.songList::-webkit-scrollbar{
	display: none;
}

.singerList,
.songList{
	scrollbar-width: none;
	-ms-overflow-style: none;
	/* height: '100%'; */
	overflow-Y: 'scroll';
}

@media (max-width: 1000px) {
	.dashboardWrapper{
		grid-gap: 0;
		grid-template-columns: 100% 0%;
	}
}