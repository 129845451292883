.listContents::-webkit-scrollbar{
	display: none;
}

.listContents{
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.listContents {
	height:100%;
	overflow-y:scroll;
}

.listContainer {
	display: grid;
    grid-gap: 3px;
	padding:6px;
    grid-template-columns: 30px auto 120px 30px;
	color:white;
	border-bottom:1px solid rgb(25,25,25);
}

.listContainer:not(.header):hover {
	background-color: rgb(25,25,25);
}

.showOnHover {
	visibility: hidden;
}

.listContainer:hover > .showOnHover {
	visibility: visible;
}


.borderBottom {
	border-bottom: 1px solid rgb(75,75,75);
}

@media (max-width: 1300px) {
	.listContainer {
		grid-gap: 2px;
		padding:5px;
		grid-template-columns: 25px auto 100px 30px;
		font-size:12px;
	}

	.listContainer :global(.btn-link) {
		font-size:12px !important;
	}
}
@media (max-width: 1000px) {
	.showWithoutHoverOnMobile {
		visibility:visible;
	}
}