.settingsModal input:disabled{
	color:gray;
	background-color:lightgray;
}

.faToggle {
	font-size:24px;
	margin-top:-5px;
	margin-bottom:-5px;
}

.faToggle :global(.fa-toggle-off) {
	color:gray;
}

.faToggle :global(.fa-toggle-on) {
	color: #58a4f5;
}

.listContainer {
	display: grid;
    grid-gap: 5px;
	padding:10px;
    grid-template-columns: 20px 150px auto 100px 40px;
	grid-template-areas:"songNumber singerName performance status control";
	color:white;
	border-top:1px solid gray;
}

.songNumber {
	grid-area: songNumber;
}

.singerName {
	grid-area: singerName;
}

.performance {
	grid-area: performance;
}

.status {
	grid-area: status;
}

.control {
	grid-area: control;
}

.toggle :global(a.disabled){
	color:rgb(200,200,200);
	font-style:italic	;
}

@media (max-width: 1000px) {
	.listContainer {
		grid-template-columns: auto 100px 40px;
		grid-template-areas:
		"singerName singerName status"
		"performance performance control";
		font-size:12px;
	}
	.songNumber {
		display:none;
	}
	.status {
		text-align:right;
	}

	.statusInner {
		float:right;
	}
}