.navBar {
	background: transparent;
	--bs-bg-opacity:0;
	padding:0.25em 2em;
}

.navBar :global(.navbar-toggler:focus) {
	box-shadow: 0 0 0 1px;
}

.logoText {
	font-size: 3em;
	font-family: "Yellowtail", sans-serif;
	color: #fff;
	text-shadow:
		0 0 7px #fff,
		0 0 10px #fff,
		0 0 21px #fff,
		0 0 42px #5271ff,
		0 0 82px #5271ff,
		0 0 92px #5271ff,
		0 0 102px #5271ff,
		0 0 151px #5271ff;
}

.logoTextSmall {
	font-size: 2em;
	font-family: "Yellowtail", sans-serif;
	color: #fff;
	text-shadow:
		0 0 7px #fff,
		0 0 10px #fff,
		0 0 21px #fff,
		0 0 42px #5271ff,
		0 0 82px #5271ff,
		0 0 92px #5271ff,
		0 0 102px #5271ff,
		0 0 151px #5271ff;
}

.titleText {
	color: rgba(255,255,255,0.6);
	font-size:16px;
	font-style:italic;
	margin-top:-10px;
}
@media (max-width: 768px) {
	.navBar {
		background: transparent;
		--bs-bg-opacity:0;
		padding:0.25em 0.5em;
	}
	.logoText {
		font-size: 2em;
	}
}

