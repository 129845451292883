.player {
	background-color: black;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 30% auto 30%;
	grid-template-areas:
		"nowPlaying  controls  right";
	padding: 10px;
}

.nowPlaying {
	grid-area: nowPlaying;
	max-width: 400px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
}

.nowPlaying a {
	max-width: 400px;
	text-overflow: ellipsis;
}

.nowPlayingTime {
	float: right;
	display: none;
}

.controls {
	text-align: center;
	grid-area: controls;
}

.right {
	grid-area: right;
}

.player.enabled {
	color: lightblue;
	cursor: pointer;
}

.launchToggle {
	position: relative;
	top: 50%;
	margin-top: -10px;
	text-align: right;
}

.player .icon {
	font-size: 22px;
}

.player .icon {
	color: white;
	font-size: 34px;
	padding-left: 10px;
	padding-right: 10px;
	vertical-align: middle;
}

.icon:hover {
	cursor: pointer;
	color: var(--bs-success);
}

.body {
	overflow-y: hidden;
}

@media (max-width: 1000px) {
	.controls {
		display: none;
	}

	.player {
		grid-template-columns: auto 0 0;
	}

	.right {
		display: none;
	}


	.player {
		border-top: 1px solid rgb(75, 75, 75)
	}

	.nowPlayingTime {
		display: block;
	}
}