.icon {
    font-size: 22px;
}

.icon {
    color: white;
    font-size: 34px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
}

.icon.navigate {
    font-size: 28px;
    color: rgb(179, 179, 179);
}

.icon {
    color: white;
    font-size: 34px;
    padding-left: 10px;
    padding-right: 10px;
}

.iconHidden {
    display: none;
}

.icon:hover,
.icon.navigate:hover {
    cursor: pointer;
    color: var(--bs-success);
}

.controlsLine1 {
    width: 100%;
    text-align: center;
}

.controlsLine2 {
    display: grid;
    grid-template-columns: [first] auto [line2] 1fr [col3-start] auto [end];
    gap: 15px;
}